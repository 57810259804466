<template>
  <section>
    <FormulateForm
      ref="orderForm"
      name="orderForm"
    >
      <b-card-actions
        :show-loading="loading"
        action-collapse
        :title="dataCardTitle"
      >
        <b-row v-if="isEdit">
          <b-col md="2">
            <FormulateInput
              id="order_maintain-status"
              v-model="order.status"
              type="label"
              :label="$t('Status Venda')"
              :options="saleStatusOptions()"
              is-badge
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="order_maintain-delivery_status"
              v-model="order.deliveryStatus"
              type="label"
              :label="$t('Status Entrega')"
              :options="deliveryStatusOptions()"
              is-badge
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="order_maintain-origin"
              :value="saleOriginLabel[order.origin] || '-'"
              type="label"
              :label="$t('Origem')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="order_maintain-created-date"
              :value="order.createdDate"
              type="label"
              filter="datetime"
              :label="$t('Data de Criação')"
            />
          </b-col>
          <b-col
            v-if="order.invoice"
            md="2"
          >
            <FormulateInput
              id="order_maintain-invoice-id"
              :value="`${order.invoice.invoiceNumber} | ${invoiceModelLabelShort[order.invoice.model]
              }`"
              type="label"
              :label="$t('Nota fiscal')"
              router-name="invoice-maintain"
              :router-params="{ id: order.invoice.id }"
              :router-title="`${$t('Visualizar nota fiscal')} - ${statusInvoiceLabel[order.invoice.status]
              }`"
            />
          </b-col>
          <b-col
            v-if="order.barTabConsumptionId"
            md="2"
          >
            <FormulateInput
              id="order_maintain-consumption_id"
              v-model="order.barTabConsumptionId"
              :label="$t('Consumo')"
              type="label"
              :icons="consumptionIdLabelIcons"
              @link="onShowConsumptionSidebar"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <!-- alteração do valor é feito no método onSelectStore -->
            <e-store-combo
              id="order_maintain-store_id"
              :value="order.storeId"
              :required="true"
              :clearable="false"
              :disabled="isReadOnly || isEdit"
              @input="onSelectStore"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="order_maintain-price_table"
              v-model="order.priceTableId"
              type="label"
              :label="$t('Tabela de preço')"
              :options="getComboPriceTables"
              class="required"
              validation="required"
              :instruction="$t('Tabela de preço do cliente no momento que o pedido foi criado.')"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              v-if="!(isReadOnly || isConsignedWithPayment || isEdit)"
              id="order_maintain-is_consigned"
              v-model="order.isConsigned"
              type="switch"
              :label="$t('Consignado?')"
              :disabled="isReadOnly"
              @input="onChangeConsign"
            />

            <div
              v-else
              style="max-width: 100px"
            >
              <p class="h6">
                {{ $t('Consignado?') }}
              </p>
              <e-status-badge
                :status="!!order.isConsigned"
                type="yesNo"
                :title="!isReadOnly ? $t('SALE_ORDER.UPDATE_ERROR.CONSIGNED_CANNOT_BE_CHANGED') : ''
                "
              />
            </div>
          </b-col>

          <b-col md="2">
            <e-slot-authenticated
              id="order-auth_discount_type"
              v-model="order.discountAuth"
              :delegable-permission="discountPermission"
              :store-id="order.store ? order.store.id : 0"
              :is-valid-store="!!order.store"
              :is-read-only="isReadOnly"
            >
              <template #content="slotProps">
                <FormulateInput
                  id="order-discount_type"
                  v-model="order.discountType"
                  type="radio"
                  :options="{
                    [discountTypesEnum.VALUE]: 'R$',
                    [discountTypesEnum.PERCENTAGE]: '%',
                  }"
                  :label="$t('Desconto em')"
                  :element-class="['d-flex', 'mt-1']"
                  :disabled="slotProps.isReadOnly"
                />
              </template>
            </e-slot-authenticated>
          </b-col>

          <b-col md="2">
            <e-slot-authenticated
              v-model="order.discountAuth"
              :delegable-permission="discountPermission"
              :store-id="order.store ? order.store.id : 0"
              :is-valid-store="!!order.store"
              :is-read-only="isReadOnly"
            >
              <template #content="slotProps">
                <FormulateInput
                  v-if="slotProps.isReadOnly"
                  id="order-discount"
                  v-model="order.discount"
                  name="discount_percentage"
                  type="label"
                  :label="`${$t('Desconto')} (${getDiscountSymbol})`"
                  :filter="isDiscountPercent ? 'percentage' : 'currency'"
                  :instruction="$t('Desconto do pedido')"
                />
                <FormulateInput
                  v-else
                  id="order-discount"
                  v-model="order.discount"
                  name="discount_percentage"
                  :type="isDiscountPercent ? 'text-percetage' : 'text-number'"
                  :label="`${$t('Desconto')} (${getDiscountSymbol})`"
                  :currency="getDiscountSymbol"
                  :currency-symbol-position="isDiscountPercent ? 'suffix' : 'prefix'"
                  :precision="2"
                  :instruction="$t('Desconto do pedido')"
                  :disabled="isReadOnly"
                />
              </template>
            </e-slot-authenticated>
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="order_maintain-echope_classification"
              v-model="order.echopeClassification"
              :type="isReadOnly ? 'label' : 'vue-select'"
              :label="$t('Classificação echope')"
              :placeholder="$t('Selecione')"
              class="required"
              validation="required"
              :clearable="false"
              :options="echopeClassificationOptions()"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              v-if="canEditDelivery"
              id="order_maintain-is_delivery"
              v-model="order.isDelivery"
              type="switch"
              :label="$t('Entrega?')"
              :disabled="isReadOnly"
              @input="onChangeDelivery"
            />

            <div
              v-else
              style="max-width: 100px"
            >
              <p class="h6">
                {{ $t('Entrega?') }}
              </p>
              <e-status-badge
                :status="!!order.isDelivery"
                type="yesNo"
                :title="titleNotEditDelivery"
              />
            </div>
          </b-col>

          <template v-if="showDeliveryData">
            <b-col md="2">
              <FormulateInput
                id="order_maintain-delivery_date"
                v-model="order.deliveryDate.date"
                type="datepicker"
                no-flip
                :min="today"
                :placeholder="$t('dd/mm/aaaa')"
                :label="$t('Data de entrega')"
                class="required"
                validation="required"
                :disabled="isReadOnly || isEdit"
                :date-disabled-fn="disabledDeliveryDate"
                @blur="onBlurDeliveryDate"
              />
            </b-col>
            <b-col md="2">
              <FormulateInput
                id="order_maintain-delivery_time"
                v-model="order.deliveryDate.time"
                type="timepicker"
                :min="today"
                :placeholder="$t('hh:mm')"
                :label="$t('Hora de entrega')"
                :disabled="isReadOnly"
              />
            </b-col>
            <b-col>
              <FormulateInput
                id="order_maintain-delivery_observation"
                v-model="order.deliveryObservation"
                type="textarea"
                :label="$t('Observação da entrega')"
                :disabled="isReadOnly"
              />
            </b-col>
          </template>
        </b-row>
      </b-card-actions>

      <b-card-actions
        v-if="showCancelData"
        no-actions
        :title="$t('Dados do cancelamento')"
      >
        <b-row>
          <b-col md="3">
            <FormulateInput
              id="order_maintain-canceled_date"
              v-model="order.cancelationDate"
              :label="$t('Data/Hora')"
              type="label"
              filter="datetime"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="order_maintain-canceled_user"
              v-model="getCancelationUser"
              :label="$t('Usuário que cancelou')"
              type="label"
            />
          </b-col>

          <b-col>
            <FormulateInput
              id="order_maintain-cancel_observation"
              v-model="order.cancelationReason"
              :label="$t('Motivo')"
              type="label"
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <b-row>
        <b-col :md="showDeliveryAddressCard ? 6 : 12">
          <b-card-actions
            :show-loading="loading"
            action-collapse
            :title="$t('Dados do Cliente')"
          >
            <b-row>
              <b-col md="8">
                <e-person-search
                  id="order_maintain-customer"
                  v-model="order.customerId"
                  is-customer
                  :label="$t('Cliente')"
                  required
                  :is-read-only="isReadOnly && !clientChangeEnabled"
                  :default-options="customerDefaultOptions"
                  @input="onSelectCustomer"
                />
              </b-col>
              <template v-if="order.customer">
                <b-col md="4">
                  <FormulateInput
                    id="order_maintain-document"
                    v-model="order.customer.document"
                    type="label"
                    :label="$t('CPF/CNPJ')"
                    filter="cpfCnpj"
                  />
                </b-col>
                <b-col md="6">
                  <FormulateInput
                    id="order_maintain-email"
                    v-model="order.customer.email"
                    type="label"
                    :label="$t('Email')"
                  />
                </b-col>
                <b-col md="3">
                  <FormulateInput
                    id="order_maintain-phone"
                    v-model="getCustomerTelephone.phone"
                    type="label"
                    :label="$t('Telefone')"
                    filter="phone"
                  />
                </b-col>
                <b-col md="3">
                  <FormulateInput
                    id="order_maintain-cellphone"
                    v-model="getCustomerTelephone.cellphone"
                    type="label"
                    :label="$t('Celular')"
                    filter="phone"
                  />
                </b-col>

                <b-col
                  v-if="isDelivery"
                  md="12"
                >
                  <FormulateInput
                    id="order_maintain-address_options"
                    v-model="order.addressSelected"
                    type="vue-select"
                    :label="$t('Endereço para entrega')"
                    :options="getCustomerAddressOptions"
                    :clearable="false"
                    class="required"
                    validation="required"
                    :placeholder="$t('Selecione')"
                    :disabled="isReadOnly"
                    @input="changeAddress"
                  />
                </b-col>
              </template>
            </b-row>
            <b-row class="mt-1">
              <b-col
                md="12"
                align="right"
              >
                <e-button
                  v-if="clientChangeEnabled"
                  id="cancel_change_client"
                  type="button"
                  class="mr-2"
                  variant="outline-danger"
                  icon="x-circle"
                  :text="$t('Cancelar')"
                  @click="cancelChangeClient"
                />
                <e-button
                  v-if="isReadOnly"
                  id="change_client"
                  type="button"
                  variant="primary"
                  :icon="clientChangeEnabled ? 'check-circle' : 'pencil'"
                  :text="clientChangeEnabled ? $t('Confirmar alteração') : $t('Alterar cliente')"
                  :disabled="!$can('ChangeCustomer', 'Sale')"
                  :title="!$can('ChangeCustomer', 'Sale')
                    ? $t('Você não possui permissão para alterar o cliente')
                    : ''
                  "
                  @click="changeClient"
                />
                <e-button
                  id="create_customer"
                  type="button"
                  variant="primary"
                  :icon="'plus-circle'"
                  :text="$t('Novo Cliente')"
                  :disabled="!$can('Create', 'Customer')"
                  :title="!$can('Create', 'Customer')
                    ? $t('Você não possui permissão para criar cliente')
                    : ''
                  "
                  class="ml-1"
                  @click="createCustomer"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>

        <b-col
          v-if="showDeliveryAddressCard"
          md="6"
        >
          <e-address
            v-if="!isReadOnly"
            v-model="order.deliveryAddress"
            :show-loading="loading"
          />
          <b-card-actions
            v-else
            no-actions
            :title="$t('Endereço')"
          >
            <e-address
              v-model="order.deliveryAddress"
              :show-loading="loading"
              :read-only="true"
            />
          </b-card-actions>
        </b-col>
      </b-row>

      <b-row v-if="isDelivery">
        <b-col>
          <b-card-actions
            :show-loading="loading"
            action-collapse
            :title="$t('Dados de entrega')"
          >
            <b-row>
              <b-col md="3">
                <FormulateInput
                  id="order_maintain-delivery_fee_type"
                  v-model="deliveryData.deliveryFeeType"
                  type="radio"
                  :options="deliveryFeeLabel"
                  :label="$t('Tipo da taxa de entrega')"
                  :element-class="['d-flex', 'mt-1']"
                  :disabled="!canEditDelivery"
                  :title="titleNotEditDelivery"
                  @input="onChangeDeliveryFeeType"
                />
              </b-col>

              <b-col
                v-if="order.origin === saleOriginEnum.APP || order.origin === saleOriginEnum.WEBSITE
                "
                md="2"
              >
                <FormulateInput
                  id="order_maintain-is_tax_zero"
                  :value="order.isTaxZero"
                  type="label"
                  :label="$t('Taxa zero?')"
                  :options="yesNoTypes()"
                  is-badge
                />
              </b-col>

              <template v-if="isDeliveryPerKilometer">
                <b-col md="3">
                  <FormulateInput
                    id="order-fee_per_kilometer_value"
                    v-model="deliveryData.shippingFeePerKilometer"
                    type="label"
                    :label="$t('Valor da taxa por KM da loja')"
                    filter="currency"
                    :instruction="$t('Valor da taxa por quilômetro configurada para a loja selecionada.')
                    "
                  />
                </b-col>
                <b-col md="3">
                  <FormulateInput
                    id="order-distance_value"
                    v-model="deliveryData.distanceValue"
                    type="text-number"
                    :precision="2"
                    :label="$t('Distância em KM')"
                    :validation="`required|min:${minKilometerDelivery}`"
                    class="required"
                    :instructions="[
                      { text: $t('Distância em quilômetros da loja até o endereço de entrega.') },
                      {
                        text: $t('SALE.UI.MIN_DELIVERY_KM', {
                          minKilometer: minKilometerDelivery,
                        }),
                      },
                    ]"
                    :disabled="!canEditDelivery"
                    :title="titleNotEditDelivery"
                    @blur="onUpdateDelivery"
                  />
                </b-col>
              </template>

              <template v-else>
                <b-col md="3">
                  <FormulateInput
                    id="order-single_fee_value"
                    v-model="deliveryData.shippingFee"
                    type="label"
                    :label="$t('Valor da taxa da loja')"
                    filter="currency"
                    :instruction="$t('Valor da taxa configurada para a loja selecionada.')"
                  />
                </b-col>
              </template>

              <b-col md="3">
                <e-slot-authenticated
                  v-model="deliveryData.discountAuth"
                  :delegable-permission="deliveryDiscountPermission"
                  :store-id="order.store ? order.store.id : 0"
                  :is-valid-store="!!order.store"
                  :is-read-only="isReadOnly"
                >
                  <template #content="slotProps">
                    <FormulateInput
                      v-if="slotProps.isReadOnly"
                      id="order-delivery_discount"
                      v-model="deliveryData.discount"
                      name="discount_percentage"
                      type="label"
                      :label="$t('Desconto da taxa da loja')"
                      filter="currency"
                      :instruction="$t('Valor de desconto aplicado apenas sobre o valor da entrega')
                      "
                    />
                    <FormulateInput
                      v-else
                      id="order-delivery_discount"
                      v-model="deliveryData.discount"
                      name="discount_percentage"
                      type="text-number"
                      :label="$t('Desconto da taxa de entrega')"
                      :currency="$t('R$')"
                      :validation="[['optional'], ['max', deliveryData.deliveryTax]]"
                      :precision="2"
                      :instruction="$t('Valor de desconto aplicado apenas sobre o valor da entrega')
                      "
                      :disabled="!canEditDelivery"
                      :title="titleNotEditDelivery"
                    />
                  </template>
                </e-slot-authenticated>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>

      <b-card-actions
        v-if="order.customer && order.storeId"
        :show-loading="loading || loadingProducts"
        action-collapse
        :title="$t('Produtos')"
      >
        <b-row>
          <b-col
            v-if="!isReadOnly"
            md="12"
            class="mb-1 d-flex justify-content-end"
          >
            <e-button
              data-testid="btnAddProduct"
              :text="$t('Adicionar produto')"
              variant="primary"
              @click="() => onShowProductSidebar()"
            />
          </b-col>

          <b-col md="12">
            <sale-product-table
              :items="getProducts"
              :is-read-only="isReadOnly"
              :show-remove-when-exist-item="!isConsignedWithPayment"
              :show-update-when-exist-item="!isConsignedWithPayment"
              :show-update="true"
              :show-remove="true"
              @show-sidebar-update="onShowProductSidebar"
              @remove-item="onRemoveProduct"
            />
          </b-col>
          <b-col
            v-if="hasColdOptionProducts || order.coldOption"
            md="12"
          >
            <FormulateInput
              id="order-cold-option"
              v-model="order.coldOption"
              :type="isReadOnly ? 'label' : 'radio'"
              :options="coldOptionOptions()"
              :label="$t('Estado dos produtos')"
              :element-class="['d-flex', 'mt-1', 'flex-wrap']"
              class="required"
              validation="required"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex justify-content-end">
            <div>
              <p
                v-if="isDelivery"
                class="h4 text-right"
              >
                <e-instructions :title="$t('Taxa de conveniência')" />
                {{ $t('Taxa de conveniência:') }}
                <span class="h3">
                  {{ getOrderTotalDeliveryTax | currency }}
                </span>
              </p>

              <p
                v-if="getOrderTotalDiscount > 0"
                class="h4 text-right"
              >
                {{ $t('Desconto Total:') }}
                <span class="h3">
                  {{ getOrderTotalDiscount | currency }}
                </span>
              </p>

              <p
                v-if="order.comission > 0"
                class="h4 text-right"
              >
                <e-instructions
                  :title="$t('Comissão do garçom')"
                  :instruction="$t('Valor da porcentagem da gorjeta do garçom.')"
                />
                {{ $t('Comissão:') }}
                <span class="h3">
                  {{ order.comission | currency }}
                </span>
              </p>

              <p class="h3 text-right">
                {{ $t('Total do pedido:') }}
                <span class="h2">
                  {{ getOrderTotalValue | currency }}
                </span>
              </p>
            </div>
          </b-col>
        </b-row>

        <b-row
          v-if="getReturnedProducts && getReturnedProducts.length"
          class="my-2"
        >
          <b-col>
            <p class="h5">
              {{ $t('Produtos devolvidos') }}:
            </p>
            <b-table
              show-empty
              striped
              class="bordered"
              :fields="itemsReturnedFields"
              :items="getReturnedProducts"
            >
              <template #custom-foot>
                <tr>
                  <th
                    colspan="4"
                    class="text-right"
                  >
                    Total
                  </th>
                  <th class="text-right">
                    {{ getTotalReturnedProducts | currency }}
                  </th>
                </tr>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        v-if="showProductsToReclaim"
        :show-loading="loading || loadingProducts"
        action-collapse
        :title="$t('Retorno de produtos')"
      >
        <b-row>
          <b-col>
            <!-- qnd a tabela é responsiva o datepicker fica oculto. -->
            <b-table
              show-empty
              :responsive="isReadOnly"
              striped
              class="bordered"
              :empty-text="$t('Nenhum produtos para retorno registrado')"
              :fields="toReclaimFields"
              :items="itemsToReclaim"
            >
              <template #cell(returnDateLimit)="row">
                <p
                  v-if="isReadOnly"
                  class="m-0"
                >
                  {{ row.item.returnDateLimit | date }}
                </p>
                <FormulateInput
                  v-else
                  :id="`reclaim_table-date_limit_${row.item.productAssociated ? row.item.productAssociated.id : ''
                  }_${row.item.localItemId || row.item.itemId}`"
                  v-model="row.item.returnDateLimit"
                  type="datepicker"
                  placeholder="dd/mm/yyyy"
                  :aria-label="$t('Data limite')"
                  :min="today"
                  validation="required"
                  :validation-messages="{
                    required: $t('Data limite é obrigatório.'),
                  }"
                  @input="handleDateToReturnChange(row.index, row.item.returnDateLimit)"
                />
              </template>

              <template #cell(name)="row">
                <sup class="m-0 text-primary">
                  {{ row.item.productAssociated.name }}
                </sup>

                <p class="m-0">
                  {{ row.item.skuProduct.name }}
                </p>
              </template>

              <template #cell(quantity)="row">
                {{ row.item.quantity - row.item.returnQuantityDamaged }}
              </template>

              <template #cell(contractualFine)="row">
                {{ $options.filters.currency(row.item.skuProduct.contractualFine) }}
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        v-if="order.id"
        :show-loading="loading || loadingProducts"
        action-collapse
        :title="$t('Pagamentos')"
      >
        <b-row>
          <b-col>
            <b-table
              class="bordered"
              striped
              show-empty
              :empty-text="isPix ? $t('Aguardando pagamento PIX') : $t('Nenhum pagamento registrado.')
              "
              :fields="paymentFields"
              :items="order.payments
                ? order.payments.filter(
                  (payment) => !payment.canceled || payment.paymentMethodInterface === 'Online'
                )
                : []
              "
            >
              <template #cell(paymentMethod)="row">
                <b-badge
                  v-if="row.item.canceled"
                  variant="danger"
                >
                  {{ $t('Cancelado') }}
                </b-badge>
                <span>
                  {{ row.item.paymentMethod.name }}
                </span>
                <template v-if="row.item.cardBrand || row.item.nsu">
                  (
                  <template v-if="row.item.paymentMethodInterface">
                    {{ paymentMethodInterfaceLabel[row.item.paymentMethodInterface] || '-' }}
                  </template>

                  <template v-if="row.item.cardBrand">
                    {{ row.item.cardBrand || '-' }}
                  </template>

                  <template v-if="row.item.nsu">
                    <b>NSU: </b> {{ row.item.nsu || '-' }}
                  </template>
                  )
                  <span
                    v-if="order.onlinePaymentCancelation &&
                      order.onlinePaymentCancelation.cancelRequestId
                    "
                  >&nbsp;-&nbsp;<b>{{ $t('Id do cancelamento: ') }}</b>
                    {{ order.onlinePaymentCancelation.cancelRequestId }}</span>
                </template>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-actions>

      <b-row v-if="order.origin === 'App' || order.origin === 'Website'">
        <b-col md="6">
          <b-card-actions
            v-if="order.id && (order.status === 'Completed' || order.status === 'Canceled')"
            :show-loading="loading || loadingProducts"
            action-collapse
            :title="$t('Avaliação do cliente (NPS)')"
          >
            <h5 v-if="order.npsStatus !== 'Answered'">
              {{ $t('O cliente ainda não respondeu à pesquisa NPS') }}
            </h5>
            <div v-else>
              <FormulateInput
                id="nps_average"
                v-model="order.npsAverage"
                type="star-rating"
                :label="$t('Nota final')"
                :show-number="true"
              />
              <FormulateInput
                v-for="(npsAnswer, index) in order.npsAnswers.filter((answer) => answer.answer)"
                :key="`nps_question_${index}`"
                v-model="npsAnswer.answer"
                :type="npsAnswer.questionType === 'Rating' ? 'star-rating' : 'label'"
                :label="npsAnswer.question"
              />
              <FormulateInput
                id="nps-solved"
                v-model="order.npsSolved"
                name="issueSolved"
                type="switch"
                :label="$t('Foi resolvido?')"
                @input="saveNpsSolved"
              />
            </div>
          </b-card-actions>
        </b-col>
        <b-col
          v-if="order.npsStatus === 'Answered'"
          md="6"
        >
          <b-card-actions
            v-if="order.id && (order.status === 'Completed' || order.status === 'Canceled')"
            :show-loading="loading || loadingProducts"
            action-collapse
            :title="$t('Feedback do NPS')"
          >
            <h5 v-if="!order.npsFeedbacks.length">
              {{ $t('Ainda não houve feedback da loja') }}
            </h5>
            <div v-else>
              <p
                v-for="(event, index) in order.npsFeedbacks"
                :key="`nps_event_${index}`"
              >
                <em>{{ event.createdDate | datetime }} ({{ event.createdBy }}):</em>&nbsp;<span>{{
                  event.description
                }}</span>
              </p>
            </div>
            <b-row class="mt-1">
              <b-col
                md="12"
                align="right"
              >
                <e-button
                  v-if="isReadOnly"
                  id="add_nps_feedback"
                  type="button"
                  variant="primary"
                  icon="plus"
                  :text="$t('Adicionar feedback')"
                  :title="!$can('AddNpsFeedback', 'Sale')
                    ? $t('Você não possui permissão para adicionar feedback')
                    : ''
                  "
                  @click="addNpsFeedback"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </FormulateForm>

    <fab
      :main-tooltip="$t('Ações')"
      main-icon="keyboard_command_key"
      :actions="actions"
      :fixed-tooltip="true"
      :busy="loading"
      @cancel="onCancel"
      @save-completed="saveOrder"
      @print-order="onPrint"
      @print-lending="onPrintLendingContract"
      @print-consigned-returned="onPrintConsignedReturned"
      @print-rent="onPrintRentContract"
      @print-sale-rent="onPrintSaleRent"
      @cancel-order="onCancelOrder"
    />

    <div class="pb-1" />

    <e-modal-order-cancelation
      ref="modalCancelation"
      @after-confirm="$router.push({ name: 'order-list' })"
    />

    <modal-add-nps-feedback
      ref="modalAddNpsFeedback"
      @after-confirm="addNpsFeedbackData"
    />

    <sale-product-sidebar
      v-if="order.storeId"
      ref="saleProductSidebar"
      :store-id="order.storeId"
      :price-table-id="order.priceTableId"
      :items-added="order.products"
      @update="onUpdateProduct"
      @add="onAddProduct"
    />

    <bar-tab-consumption-info-sidebar
      ref="barTabConsumptionSidebar"
      is-history-view
      read-only
      @show-sale="onHideConsumptionSidebar"
    />
  </section>
</template>

<script>
import { debounce } from 'lodash'
import { BRow, BCol, BTable, BBadge } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  deliveryDomain,
  discountTypes,
  statusTypes,
  paymentTypes,
  loadingOverlay,
  saleDomain,
  invoiceDomains,
} from '@/mixins'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import EButton from '@/views/components/EButton.vue'
import EPersonSearch from '@/views/components/inputs/EPersonSearch.vue'
import EAddress from '@/views/components/EAddress.vue'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import EInstructions from '@/views/components/EInstructions.vue'
import moment from 'moment'
import fab from '@/views/components/FAB.vue'
import isElectron from 'is-electron'
import ESlotAuthenticated from '@/views/components/ESlotAuthenticated.vue'
import delegablePermissions from '@/utils/delegable-permissions'
import SaleProductSidebar from '../components/SaleProductSidebar.vue'
import SaleProductTable from '../components/SaleProductTable.vue'
import EModalOrderCancelation from './EModalOrderCancelation.vue'
import BarTabConsumptionInfoSidebar from '../../pdv/pay-box-bar/BarTabConsumptionInfoSidebar.vue'
import ModalAddNpsFeedback from '../components/ModalAddNpsFeedback.vue'

export default {
  name: 'OrderMaintain',
  components: {
    BRow,
    BCol,
    BBadge,
    BCardActions,
    EStoreCombo,
    EButton,
    SaleProductSidebar,
    EAddress,
    EStatusBadge,
    SaleProductTable,
    BTable,
    EInstructions,
    fab,
    EModalOrderCancelation,
    EPersonSearch,
    ESlotAuthenticated,
    BarTabConsumptionInfoSidebar,
    ModalAddNpsFeedback,
  },

  mixins: [
    discountTypes,
    statusTypes,
    deliveryDomain,
    saleDomain,
    paymentTypes,
    invoiceDomains,
    loadingOverlay,
  ],

  data() {
    return {
      loading: false,
      loadingProducts: false,
      isToUpdatePrice: true,
      localDeliveryStatusLabel: null,
      clientChangeEnabled: false,
      previousMainDate: null,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    ...mapState('auth', ['userData']),
    ...mapState('pages/sale/order/orderMaintain', [
      'order',
      'deliveryData',
      'minKilometerDelivery',
      'isQuotationReceived',
      'itemsToReclaim',
    ]),
    ...mapGetters('pages/sale/order/orderMaintain', [
      'getProducts',
      'hasLendingProducts',
      'hasRentProducts',
      'hasReturnedProducts',
      'hasItemsReturned',
      'getReturnedProducts',
      'getTotalReturnedProducts',
      'getCustomerAddressOptions',
      'getCustomerTelephone',
      'getOrderTotalValue',
      'getOrderTotalDiscount',
      'getOrderTotalDeliveryTax',
      'getMaxScheduleDays',
      'getDateOfMaxSchedule',
      'hasColdOptionProducts',
    ]),
    ...mapGetters('common/priceTables', ['getComboPriceTables']),
    today() {
      return new Date()
    },
    isEdit() {
      return !!this.$route.params.id
    },

    isReadOnly() {
      return (
        !!this.$route.name.includes('read-only') ||
        this.order.status === this.saleStatusEnum.COMPLETED
      )
    },
    isPix() {
      return this.order?.onlinePayment?.paymentMethod === 'Pix'
    },
    canPrintLendingContract() {
      return isElectron() && (this.isEdit || this.isReadOnly) && this.hasLendingProducts
    },
    canPrintRentContract() {
      return isElectron() && (this.isEdit || this.isReadOnly) && this.hasRentProducts
    },
    canPrintSaleRent() {
      return isElectron() && (this.isEdit || this.isReadOnly) && this.hasRentProducts && (this.order.status === "Completed")
    },
    canPrintConsignedReturned() {
      return isElectron() && (this.isEdit || this.isReadOnly) && this.hasItemsReturned
    },

    canEditDelivery() {
      return !this.isReadOnly && !this.isConsignedWithPayment
    },
    titleNotEditDelivery() {
      return !this.isReadOnly && this.isEdit && this.isConsignedWithPayment
        ? this.$t('Não é possível alterar se pedido consignado já possui pagamento registrado')
        : ''
    },
    isDelivery() {
      return this.order.isDelivery
    },
    isDeliveryPerKilometer() {
      return this.deliveryData.deliveryFeeType === this.deliveryFeeEnum.FEE_PER_KILOMETER
    },

    showProductsToReclaim() {
      return this.itemsToReclaim?.length > 0
    },

    showDeliveryData() {
      return this.order.isDelivery || this.order.isConsigned
    },
    showCancelData() {
      return this.order?.status === this.saleStatusEnum.CANCELED
    },

    isConsignedWithPayment() {
      return this.order.isConsigned && this.order.payments?.length > 0
    },

    isDiscountPercent() {
      return this.order.discountType === this.discountTypesEnum.PERCENTAGE
    },

    getDiscountSymbol() {
      return this.isDiscountPercent ? this.$t('%') : this.$t('R$')
    },
    getCancelationUser() {
      return this.order?.cancelationUser?.name || this.$t('Não informado')
    },
    getDaysOfWeekendWithoutDelivery() {
      if (!this.order.store) return []
      const { openingHours } = this.order.store
      const weekends = {
        sunday: { name: 'sunday', value: 0 },
        monday: { name: 'monday', value: 1 },
        tuesday: { name: 'tuesday', value: 2 },
        wednesday: { name: 'wednesday', value: 3 },
        thursday: { name: 'thursday', value: 4 },
        friday: { name: 'friday', value: 5 },
        saturday: { name: 'saturday', value: 6 },
      }

      const withoutDelivery = Object.keys(openingHours || {}).filter(
        key => !openingHours[key]?.delivery
      )

      const objWithWeekendsWithoutDelivery = withoutDelivery?.reduce(
        (objResult, keyWithoutDelivery) => {
          const week = weekends[keyWithoutDelivery]
          if (!week) return objResult

          const newObjResult = { ...objResult }
          newObjResult[week.value] = true
          return newObjResult
        },
        {}
      )

      return objWithWeekendsWithoutDelivery || {}
    },

    showDeliveryAddressCard() {
      return (
        this.order.customer !== null &&
        this.order.isDelivery &&
        this.order.addressSelected?.id === -1
      )
    },

    dataCardTitle() {
      const title = `${this.$t('Dados do pedido')}${this.order?.id ? ` #${this.order.id}` : ''}`
      if (this.order.quotationId)
        return `${title} (${this.$t('Criado a partir da cotação: ')} ${this.order.quotationId})`
      return title
    },

    customerDefaultOptions() {
      const customer = this.order?.customer
      if (customer && Object.keys(customer).length > 0) return [customer]
      return []
    },

    discountPermission() {
      return delegablePermissions.ERP_ORDER_DISCOUNT
    },
    deliveryDiscountPermission() {
      return delegablePermissions.ERP_ORDER_DELIVERY_DISCOUNT
    },

    itemsReturnedFields() {
      return [
        {
          label: this.$t('Produto'),
          key: 'skuProduct.name',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Data da devolução'),
          key: 'createdDate',
          tdClass: 'text-center',
          thStyle: { width: '180px' },
          formatter: value => this.$options.filters.datetime(value),
        },
        {
          label: this.$t('Qtd.'),
          key: 'quantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '60px' },
        },
        {
          label: this.$t('Valor unitário'),
          key: 'price',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: (value, index, item) => this.$options.filters.currency(item.priceRuleSelected.priceInfo.price),
        },
        {
          label: this.$t('Total'),
          key: 'netValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: (value, index, item) => this.$options.filters.currency(item.priceRuleSelected.priceInfo.localNetValue),
        },
      ]
    },

    paymentFields() {
      return [
        {
          label: this.$t('Tipo'),
          key: 'paymentMethod',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: (value, index, item) =>
            this.$options.filters.currency(value - (item.change || 0)),
        },
      ]
    },

    toReclaimFields() {
      return [
        {
          label: this.$t('Data limite'),
          key: 'returnDateLimit',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '180px' },
        },
        {
          label: this.$t('Data do retorno'),
          key: 'returnDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '180px' },
          formatter: (value, index, item) =>
            value
              ? `${this.$options.filters.date(value)} - ${item.returnUser?.name}`
              : this.$t('Pendente'),
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Valor unitário indenização'),
          key: 'contractualFine',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '160px', minWidth: '160px' },
        },
        {
          label: this.$t('Qtd. do retorno'),
          key: 'quantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Qtd. avariado'),
          key: 'returnQuantityDamaged',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          hide: !this.isReadOnly,
        },
      ].filter(f => !f.hide)
    },

    actions() {
      return [
        {
          name: 'cancel',
          icon: 'arrow_back',
          color: 'red',
          tooltip: this.$t('Voltar'),
        },
        {
          name: 'save-completed',
          icon: 'save',
          color: 'green',
          tooltip: this.$t('Salvar'),
          hide: this.isReadOnly,
        },
        {
          name: 'cancel-order',
          icon: 'cancel',
          color: 'red',
          tooltip: this.$t('Cancelar Pedido'),
          hide:
            this.order?.status === 'Canceled' ||
            !this.order?.id ||
            this.order.origin === this.saleOriginEnum.INVOICE,
        },
        {
          name: 'print-order',
          icon: 'print',
          color: 'green',
          tooltip: this.$t('Imprimir'),
          hide:
            !this.canPrintOrder() ||
            this.order.origin === this.saleOriginEnum.INVOICE ||
            !isElectron(),
        },
        {
          name: 'print-lending',
          icon: 'print',
          color: 'green',
          tooltip: this.$t('Imprimir Contrato Comodato'),
          hide: !this.canPrintLendingContract,
        },
        {
          name: 'print-consigned-returned',
          icon: 'print',
          color: 'green',
          tooltip: this.$t('Imprimir Recibo Devolução'),
          hide: !this.canPrintConsignedReturned,
        },
        {
          name: 'print-rent',
          icon: 'print',
          color: 'green',
          tooltip: this.$t('Imprimir Contrato Aluguel'),
          hide: !this.canPrintRentContract,
        },
        {
          name: 'print-sale-rent',
          icon: 'print',
          color: 'green',
          tooltip: this.$t('Imprimir Recibo Aluguel'),
          hide: !this.canPrintSaleRent,
        },
      ].filter(a => !a.hide)
    },

    consumptionIdLabelIcons() {
      if (!this.order.barTabConsumptionId) return []

      return [
        {
          eventName: 'link',
          icon: 'eye-fill',
          clazz: '',
          variant: '',
          title: this.$t('Mostrar dados do consumo'),
        },
      ]
    },
  },
  async mounted() {
    this.fetchPriceTables()
    try {
      this.loading = true
      this.fetchMinKilometerParam()
      this.isToUpdatePrice = false
      if (this.isEdit || this.isReadOnly) {
        await this.stFetchOrderById(this.$route.params.id)
        await this.stUpdateDeliveryTax()

        this.localDeliveryStatusLabel = this.deliveryStatusLabel[this.order.deliveryStatus]
      }

      if (this.isQuotationReceived) {
        await this.stQuotationToOrder()
      }
      await new Promise(resolve => setTimeout(() => resolve(), 500))
    } catch (err) {
      this.showError({ message: err.message })
    } finally {
      this.loading = false
      if (!this.isConsignedWithPayment || !this.isReadOnly) this.isToUpdatePrice = true
    }
    this.previousMainDate = null
  },

  methods: {
    ...mapActions('common/priceTables', ['fetchPriceTables']),
    ...mapActions('pages/sale/order/orderMaintain', {
      stFetchOrderById: 'fetchOrderById',
      stFetchStoreById: 'fetchStoreById',
      stFetchCustomerById: 'fetchCustomerById',
      stSetOrderStoreId: 'setOrderStoreId',
      stSaveOrder: 'saveOrder',
      stAddItem: 'addProduct',
      stUpdateItem: 'updateProduct',
      stRemoveItem: 'removeProduct',
      stSetDeliveryAddress: 'setDeliveryAddress',
      stCleanState: 'cleanState',
      stCleanDeliveryAddress: 'cleanDeliveryAddress',
      stCleanInputsAuth: 'cleanInputsAuth',
      stCleanOrderCustomer: 'cleanOrderCustomer',
      stUpdateProductPrices: 'updateProductPrices',
      stQuotationToOrder: 'quotationToOrder',
      stUpdateDeliveryTax: 'updateDeliveryTax',
      updateCustomer: 'updateCustomer',
      stResetToOriginal: 'resetToOriginal',
      stSetDeliveryDateAuth: 'setDeliveryDateAuth',
      fetchMinKilometerParam: 'fetchMinKilometerParam',
      stAddNpsFeedback: 'addNpsFeedback',
    }),
    ...mapActions('pages/sale/order', ['printOrder', 'fetchSaleById']),
    ...mapActions('pages/sale/clients/clientMaintain', {
      stClientMaintainCleanState: 'cleanState',
    }),

    addNpsFeedback() {
      this.$refs.modalAddNpsFeedback.show(this.order.id)
    },

    addNpsFeedbackData(feedbackData) {
      this.stAddNpsFeedback({
        createdBy: this.userData?.fullName,
        createdDate: Date.now(),
        ...feedbackData,
      })
    },

    onShowProductSidebar(item) {
      this.$refs.saleProductSidebar.show(item)
    },

    onAddProduct(formData) {
      this.stAddItem({ formData })
    },
    onUpdateProduct(formData) {
      this.stUpdateItem({ formData })
    },
    async onRemoveProduct(item) {
      const confirmed = await this.confirm()
      if (confirmed) {
        this.stRemoveItem({ id: item.id || item.localId })
      }
    },

    canPrintOrder() {
      if (!isElectron()) {
        return false
      }
      if (!this.isReadOnly) {
        return false
      }

      return true
    },

    async onPrint() {
      try {
        this.busy = true
        this.showLoadingOverlay(this.$t('Buscando dados para impressão'))
        const orderPrint = await this.fetchSaleById(this.order.id)

        const validToPrint = this.mxValidatePrintForOnlineSale(orderPrint)
        if (!validToPrint.valid) {
          this.showWarning({ message: validToPrint.message })
          return
        }

        this.showLoadingOverlay(this.$t('Imprimindo cupom'))
        await this.printOrder({ orderPrint, template: 'SALE_ORDER' })

        this.showSuccess({ message: '' })
      } catch (err) {
        /* eslint-disable no-console */
        console.error('print:', err)
        this.busy = false
      } finally {
        this.hideLoadingOverlay()
      }
    },

    async onPrintLendingContract() {
      if (!this.hasLendingProducts) {
        return
      }
      await this.onManagePrint('Imprimindo Contrato Comodato', 'LENDING_CONTRACT')
    },

    async onPrintRentContract() {
      if (!this.hasRentProducts) {
        return
      }
      await this.onManagePrint('Imprimindo Contrato Aluguel', 'RENT_CONTRACT')
    },

    async onPrintSaleRent() {
      if (!this.hasRentProducts) {
        return
      }
      await this.onManagePrint('Imprimindo Recibo de Aluguel', 'SALE_RENT')
    },

    async onPrintConsignedReturned() {
      if (!this.hasItemsReturned) {
        return
      }

      if (this.order.status !== "Completed") {
        this.showWarning({ message: "O Recibo de devolução somente poderá ser impresso em vendas finalizadas." })
        return
      }

      await this.onManagePrint('Imprimindo Recibo de Devolução', 'SALE_RETURN')
    },

    async onCancelOrder() {
      if (
        this.order?.invoice?.model === 'NFE' &&
        this.order?.invoice?.invoiceCategory === 'SaleFromPdv'
      ) {
        this.showInvalidDataMessage({
          message: this.$t(
            'Existe uma NF-e atrelada a esta venda! Favor realizar o cancelamento pelo PDV.'
          ),
        })
        return
      }
      this.$refs.modalCancelation.show(this.order.id, this.order)
    },

    async onManagePrint(title, template) {
      try {
        this.busy = true
        this.showLoadingOverlay(this.$t(title))
        const orderPrint = await this.fetchSaleById(this.order.id)
        await this.printOrder({ orderPrint, template })

        this.showSuccess({ message: '' })
      } catch (err) {
        /* eslint-disable no-console */
        console.error('print:', err)
        this.busy = false
      } finally {
        this.hideLoadingOverlay()
      }
    },

    async saveOrder() {
      if (this.loadingProducts) {
        this.showWarning({ message: this.$t('Aguarde o carregamento de produtos.') })
        return
      }

      this.$refs.orderForm.showErrors()
      if (this.$refs.orderForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      if (!this.isEdit && this.showDeliveryData) {
        const momentDate = moment(this.order.deliveryDate.date)?.format('YYYY-MM-DD')
        const time = this.order.deliveryDate.time || moment().format('HH:mm')
        const deliveryDate = moment(`${momentDate} ${time}`, 'YYYY-MM-DD HH:mm')

        if (deliveryDate.isValid() && deliveryDate.isSameOrBefore(this.today)) {
          this.showInvalidDataMessage({
            message: this.$t('Data de entrega não pode ser retroativa.'),
          })
          return
        }
      }

      try {
        this.loading = true
        await this.stSaveOrder()

        if (isElectron() && (this.hasLendingProducts || this.hasRentProducts)) {
          const confirmed = await this.confirm({
            title: this.$t('Contrato comodato/aluguel'),
            text: this.$t(
              'Venda possui itens de comodato e/ou aluguel, deseja imprimir o(s) contrato(s) agora ?'
            ),
            confirmButtonText: this.$t('Sim'),
            cancelButtonText: this.$t('Não'),
          })
          if (confirmed) {
            await this.onPrintLendingContract()
            await this.onPrintRentContract()
          }
        }
        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })
        this.stCleanState()
        this.$router.push({ name: 'order-list' })
      } catch (err) {
        this.showError({ error: err })
      } finally {
        this.loading = false
      }
    },

    async onSelectStore(storeId) {
      if (!storeId || this.loading) return

      const oldStoreId = this.order.storeId
      if (oldStoreId && storeId !== oldStoreId) {
        const confirmed = await this.confirm({
          text: this.$t(
            'Ao alterar a loja, os valores de delivery e produtos podem ser atualizados e os campos que precisam de autorização terão valor e autorização resetados.'
          ),
        })

        if (!confirmed) {
          // necessário para forçar o combo a alterar o valor.
          await this.stSetOrderStoreId({ storeId: null })
          await this.stSetOrderStoreId({ storeId: oldStoreId })
          return
        }
        await this.stCleanInputsAuth()
      }

      await this.stSetOrderStoreId({ storeId })
      await this.stFetchStoreById({ storeId })
      await this.onUpdateDelivery()
      this.updatePrices()
    },
    async onBlurDeliveryDate(val) {
      const date = moment(val)
      if (!date.isValid()) return

      if (date.toDate() > this.getDateOfMaxSchedule) {
        if (!this.order.storeId) {
          this.showWarning({ message: 'Favor selecionar a loja primeiro.' })
          await this.stResetToOriginal({ type: 'deliveryDate' })
          return
        }

        const confirmed = await this.confirm({
          title: this.$t('Alterar o data da entrega?'),
          text: this.$t('SALE_ORDER.ALERT.CONFIRM_DELIVERY_MAX_SCHEDULE', {
            maxDay: this.getMaxScheduleDays,
          }),
        })

        if (!confirmed) {
          await this.stResetToOriginal({ type: 'deliveryDate' })
          return
        }

        try {
          const delegateValue = await this.modalAuthRef.auth(
            delegablePermissions.ERP_ORDER_OVERFLOW_MAX_DELIVERY_DATE,
            this.order.storeId
          )
          this.stSetDeliveryDateAuth(delegateValue)
        } catch (error) {
          await this.stResetToOriginal({ type: 'deliveryDate' })
        }
      }
    },
    onChangeDeliveryFeeType(val) {
      if (!val) return
      this.onUpdateDelivery()
    },
    onChangeDelivery(val) {
      if (val) this.onUpdateDelivery()
    },
    onChangeConsign() {
      this.onUpdateDelivery()
    },

    onUpdateDelivery() {
      if (this.isReadOnly || this.loading) return
      this.stUpdateDeliveryTax()
    },

    async onSelectCustomer(val) {
      if (val) {
        try {
          await this.stFetchCustomerById({ customerId: val })
          await this.updatePrices()
        } catch (error) {
          this.showError({ error })
        }
      } else {
        this.stCleanOrderCustomer()
      }
    },

    // eslint-disable-next-line func-names
    updatePrices: debounce(async function () {
      if (!this.isToUpdatePrice) return

      try {
        this.loadingProducts = true
        await this.stUpdateProductPrices()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loadingProducts = false
      }
    }, 400),

    onCancel() {
      this.stCleanState()
      this.$router.push({ name: 'order-list' })
    },

    changeAddress(addressData) {
      if (addressData) this.stSetDeliveryAddress({ addressData })
      else this.stCleanDeliveryAddress()
    },

    formatDiscount(value, type) {
      return type === this.discountTypesEnum.PERCENTAGE
        ? this.$options.filters.percentage(value)
        : this.$options.filters.currency(value)
    },

    async createCustomer() {
      await this.stClientMaintainCleanState()
      this.$router.push({
        name: 'client-add',
        params: {
          callbackRouteName: 'order-add',
        },
      })
    },
    async changeClient() {
      if (this.clientChangeEnabled) {
        try {
          this.loading = true
          await this.updateCustomer()
          this.showSuccess({ message: this.$t('Cliente alterado com sucesso') })
          this.clientChangeEnabled = false
        } catch (error) {
          this.showError({ error })
        } finally {
          this.loading = false
        }
      } else {
        this.clientChangeEnabled = true
      }
    },

    async cancelChangeClient() {
      try {
        this.loading = true
        await this.stFetchOrderById(this.$route.params.id)
        this.clientChangeEnabled = false
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },

    // referência: https://bootstrap-vue.org/docs/components/form-datepicker#disabling-dates
    disabledDeliveryDate(ymd, date) {
      // weekends (Sunday = `0`, Saturday = `6`)
      const weekday = date.getDay()
      // Return `true` if the date should be disabled
      return this.getDaysOfWeekendWithoutDelivery[weekday]
    },
    handleDateToReturnChange(productIndex, dateString) {
      if (productIndex === 0) {
        this.itemsToReclaim.forEach(item => {
          if (
            (!item.returnDateLimit || item.returnDateLimit === this.previousMainDate) &&
            dateString
          ) {
            // eslint-disable-next-line no-param-reassign
            item.returnDateLimit = dateString
          }
        })
        this.previousMainDate = dateString
      }
      return dateString
    },

    onShowConsumptionSidebar() {
      this.$refs.barTabConsumptionSidebar.show(this.order.barTabConsumptionId)
    },
    onHideConsumptionSidebar() {
      this.$refs.barTabConsumptionSidebar.hide()
    },
    async saveNpsSolved() {
      if (!this.loading) {
        try {
          this.loading = true
          await this.$http.put(
            `/api/sales/${this.order.id}/${this.order.npsSolved ? 'solve-nps' : 'unsolve-nps'}`
          ) // this.stFetchOrderById(this.$route.params.id)
        } catch (error) {
          this.showError({ error })
        } finally {
          this.loading = false
        }
      }
    },
  },
}
</script>
