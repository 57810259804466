var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        { ref: "orderForm", attrs: { name: "orderForm" } },
        [
          _c(
            "b-card-actions",
            {
              attrs: {
                "show-loading": _vm.loading,
                "action-collapse": "",
                title: _vm.dataCardTitle,
              },
            },
            [
              _vm.isEdit
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "order_maintain-status",
                              type: "label",
                              label: _vm.$t("Status Venda"),
                              options: _vm.saleStatusOptions(),
                              "is-badge": "",
                            },
                            model: {
                              value: _vm.order.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.order, "status", $$v)
                              },
                              expression: "order.status",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "order_maintain-delivery_status",
                              type: "label",
                              label: _vm.$t("Status Entrega"),
                              options: _vm.deliveryStatusOptions(),
                              "is-badge": "",
                            },
                            model: {
                              value: _vm.order.deliveryStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.order, "deliveryStatus", $$v)
                              },
                              expression: "order.deliveryStatus",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "order_maintain-origin",
                              value:
                                _vm.saleOriginLabel[_vm.order.origin] || "-",
                              type: "label",
                              label: _vm.$t("Origem"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "order_maintain-created-date",
                              value: _vm.order.createdDate,
                              type: "label",
                              filter: "datetime",
                              label: _vm.$t("Data de Criação"),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.order.invoice
                        ? _c(
                            "b-col",
                            { attrs: { md: "2" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "order_maintain-invoice-id",
                                  value:
                                    _vm.order.invoice.invoiceNumber +
                                    " | " +
                                    _vm.invoiceModelLabelShort[
                                      _vm.order.invoice.model
                                    ],
                                  type: "label",
                                  label: _vm.$t("Nota fiscal"),
                                  "router-name": "invoice-maintain",
                                  "router-params": { id: _vm.order.invoice.id },
                                  "router-title":
                                    _vm.$t("Visualizar nota fiscal") +
                                    " - " +
                                    _vm.statusInvoiceLabel[
                                      _vm.order.invoice.status
                                    ],
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.order.barTabConsumptionId
                        ? _c(
                            "b-col",
                            { attrs: { md: "2" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "order_maintain-consumption_id",
                                  label: _vm.$t("Consumo"),
                                  type: "label",
                                  icons: _vm.consumptionIdLabelIcons,
                                },
                                on: { link: _vm.onShowConsumptionSidebar },
                                model: {
                                  value: _vm.order.barTabConsumptionId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.order,
                                      "barTabConsumptionId",
                                      $$v
                                    )
                                  },
                                  expression: "order.barTabConsumptionId",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: {
                          id: "order_maintain-store_id",
                          value: _vm.order.storeId,
                          required: true,
                          clearable: false,
                          disabled: _vm.isReadOnly || _vm.isEdit,
                        },
                        on: { input: _vm.onSelectStore },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "order_maintain-price_table",
                          type: "label",
                          label: _vm.$t("Tabela de preço"),
                          options: _vm.getComboPriceTables,
                          validation: "required",
                          instruction: _vm.$t(
                            "Tabela de preço do cliente no momento que o pedido foi criado."
                          ),
                        },
                        model: {
                          value: _vm.order.priceTableId,
                          callback: function ($$v) {
                            _vm.$set(_vm.order, "priceTableId", $$v)
                          },
                          expression: "order.priceTableId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      !(
                        _vm.isReadOnly ||
                        _vm.isConsignedWithPayment ||
                        _vm.isEdit
                      )
                        ? _c("FormulateInput", {
                            attrs: {
                              id: "order_maintain-is_consigned",
                              type: "switch",
                              label: _vm.$t("Consignado?"),
                              disabled: _vm.isReadOnly,
                            },
                            on: { input: _vm.onChangeConsign },
                            model: {
                              value: _vm.order.isConsigned,
                              callback: function ($$v) {
                                _vm.$set(_vm.order, "isConsigned", $$v)
                              },
                              expression: "order.isConsigned",
                            },
                          })
                        : _c(
                            "div",
                            { staticStyle: { "max-width": "100px" } },
                            [
                              _c("p", { staticClass: "h6" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Consignado?")) + " "
                                ),
                              ]),
                              _c("e-status-badge", {
                                attrs: {
                                  status: !!_vm.order.isConsigned,
                                  type: "yesNo",
                                  title: !_vm.isReadOnly
                                    ? _vm.$t(
                                        "SALE_ORDER.UPDATE_ERROR.CONSIGNED_CANNOT_BE_CHANGED"
                                      )
                                    : "",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("e-slot-authenticated", {
                        attrs: {
                          id: "order-auth_discount_type",
                          "delegable-permission": _vm.discountPermission,
                          "store-id": _vm.order.store ? _vm.order.store.id : 0,
                          "is-valid-store": !!_vm.order.store,
                          "is-read-only": _vm.isReadOnly,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "content",
                            fn: function (slotProps) {
                              var _obj
                              return [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "order-discount_type",
                                    type: "radio",
                                    options:
                                      ((_obj = {}),
                                      (_obj[_vm.discountTypesEnum.VALUE] =
                                        "R$"),
                                      (_obj[_vm.discountTypesEnum.PERCENTAGE] =
                                        "%"),
                                      _obj),
                                    label: _vm.$t("Desconto em"),
                                    "element-class": ["d-flex", "mt-1"],
                                    disabled: slotProps.isReadOnly,
                                  },
                                  model: {
                                    value: _vm.order.discountType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.order, "discountType", $$v)
                                    },
                                    expression: "order.discountType",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.order.discountAuth,
                          callback: function ($$v) {
                            _vm.$set(_vm.order, "discountAuth", $$v)
                          },
                          expression: "order.discountAuth",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("e-slot-authenticated", {
                        attrs: {
                          "delegable-permission": _vm.discountPermission,
                          "store-id": _vm.order.store ? _vm.order.store.id : 0,
                          "is-valid-store": !!_vm.order.store,
                          "is-read-only": _vm.isReadOnly,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "content",
                            fn: function (slotProps) {
                              return [
                                slotProps.isReadOnly
                                  ? _c("FormulateInput", {
                                      attrs: {
                                        id: "order-discount",
                                        name: "discount_percentage",
                                        type: "label",
                                        label:
                                          _vm.$t("Desconto") +
                                          " (" +
                                          _vm.getDiscountSymbol +
                                          ")",
                                        filter: _vm.isDiscountPercent
                                          ? "percentage"
                                          : "currency",
                                        instruction:
                                          _vm.$t("Desconto do pedido"),
                                      },
                                      model: {
                                        value: _vm.order.discount,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.order, "discount", $$v)
                                        },
                                        expression: "order.discount",
                                      },
                                    })
                                  : _c("FormulateInput", {
                                      attrs: {
                                        id: "order-discount",
                                        name: "discount_percentage",
                                        type: _vm.isDiscountPercent
                                          ? "text-percetage"
                                          : "text-number",
                                        label:
                                          _vm.$t("Desconto") +
                                          " (" +
                                          _vm.getDiscountSymbol +
                                          ")",
                                        currency: _vm.getDiscountSymbol,
                                        "currency-symbol-position":
                                          _vm.isDiscountPercent
                                            ? "suffix"
                                            : "prefix",
                                        precision: 2,
                                        instruction:
                                          _vm.$t("Desconto do pedido"),
                                        disabled: _vm.isReadOnly,
                                      },
                                      model: {
                                        value: _vm.order.discount,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.order, "discount", $$v)
                                        },
                                        expression: "order.discount",
                                      },
                                    }),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.order.discountAuth,
                          callback: function ($$v) {
                            _vm.$set(_vm.order, "discountAuth", $$v)
                          },
                          expression: "order.discountAuth",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "order_maintain-echope_classification",
                          type: _vm.isReadOnly ? "label" : "vue-select",
                          label: _vm.$t("Classificação echope"),
                          placeholder: _vm.$t("Selecione"),
                          validation: "required",
                          clearable: false,
                          options: _vm.echopeClassificationOptions(),
                        },
                        model: {
                          value: _vm.order.echopeClassification,
                          callback: function ($$v) {
                            _vm.$set(_vm.order, "echopeClassification", $$v)
                          },
                          expression: "order.echopeClassification",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _vm.canEditDelivery
                        ? _c("FormulateInput", {
                            attrs: {
                              id: "order_maintain-is_delivery",
                              type: "switch",
                              label: _vm.$t("Entrega?"),
                              disabled: _vm.isReadOnly,
                            },
                            on: { input: _vm.onChangeDelivery },
                            model: {
                              value: _vm.order.isDelivery,
                              callback: function ($$v) {
                                _vm.$set(_vm.order, "isDelivery", $$v)
                              },
                              expression: "order.isDelivery",
                            },
                          })
                        : _c(
                            "div",
                            { staticStyle: { "max-width": "100px" } },
                            [
                              _c("p", { staticClass: "h6" }, [
                                _vm._v(" " + _vm._s(_vm.$t("Entrega?")) + " "),
                              ]),
                              _c("e-status-badge", {
                                attrs: {
                                  status: !!_vm.order.isDelivery,
                                  type: "yesNo",
                                  title: _vm.titleNotEditDelivery,
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _vm.showDeliveryData
                    ? [
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              staticClass: "required",
                              attrs: {
                                id: "order_maintain-delivery_date",
                                type: "datepicker",
                                "no-flip": "",
                                min: _vm.today,
                                placeholder: _vm.$t("dd/mm/aaaa"),
                                label: _vm.$t("Data de entrega"),
                                validation: "required",
                                disabled: _vm.isReadOnly || _vm.isEdit,
                                "date-disabled-fn": _vm.disabledDeliveryDate,
                              },
                              on: { blur: _vm.onBlurDeliveryDate },
                              model: {
                                value: _vm.order.deliveryDate.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.order.deliveryDate, "date", $$v)
                                },
                                expression: "order.deliveryDate.date",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "order_maintain-delivery_time",
                                type: "timepicker",
                                min: _vm.today,
                                placeholder: _vm.$t("hh:mm"),
                                label: _vm.$t("Hora de entrega"),
                                disabled: _vm.isReadOnly,
                              },
                              model: {
                                value: _vm.order.deliveryDate.time,
                                callback: function ($$v) {
                                  _vm.$set(_vm.order.deliveryDate, "time", $$v)
                                },
                                expression: "order.deliveryDate.time",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "order_maintain-delivery_observation",
                                type: "textarea",
                                label: _vm.$t("Observação da entrega"),
                                disabled: _vm.isReadOnly,
                              },
                              model: {
                                value: _vm.order.deliveryObservation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.order,
                                    "deliveryObservation",
                                    $$v
                                  )
                                },
                                expression: "order.deliveryObservation",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm.showCancelData
            ? _c(
                "b-card-actions",
                {
                  attrs: {
                    "no-actions": "",
                    title: _vm.$t("Dados do cancelamento"),
                  },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "3" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "order_maintain-canceled_date",
                              label: _vm.$t("Data/Hora"),
                              type: "label",
                              filter: "datetime",
                            },
                            model: {
                              value: _vm.order.cancelationDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.order, "cancelationDate", $$v)
                              },
                              expression: "order.cancelationDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "3" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "order_maintain-canceled_user",
                              label: _vm.$t("Usuário que cancelou"),
                              type: "label",
                            },
                            model: {
                              value: _vm.getCancelationUser,
                              callback: function ($$v) {
                                _vm.getCancelationUser = $$v
                              },
                              expression: "getCancelationUser",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "order_maintain-cancel_observation",
                              label: _vm.$t("Motivo"),
                              type: "label",
                            },
                            model: {
                              value: _vm.order.cancelationReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.order, "cancelationReason", $$v)
                              },
                              expression: "order.cancelationReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: _vm.showDeliveryAddressCard ? 6 : 12 } },
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "show-loading": _vm.loading,
                        "action-collapse": "",
                        title: _vm.$t("Dados do Cliente"),
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "8" } },
                            [
                              _c("e-person-search", {
                                attrs: {
                                  id: "order_maintain-customer",
                                  "is-customer": "",
                                  label: _vm.$t("Cliente"),
                                  required: "",
                                  "is-read-only":
                                    _vm.isReadOnly && !_vm.clientChangeEnabled,
                                  "default-options": _vm.customerDefaultOptions,
                                },
                                on: { input: _vm.onSelectCustomer },
                                model: {
                                  value: _vm.order.customerId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.order, "customerId", $$v)
                                  },
                                  expression: "order.customerId",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.order.customer
                            ? [
                                _c(
                                  "b-col",
                                  { attrs: { md: "4" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "order_maintain-document",
                                        type: "label",
                                        label: _vm.$t("CPF/CNPJ"),
                                        filter: "cpfCnpj",
                                      },
                                      model: {
                                        value: _vm.order.customer.document,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.order.customer,
                                            "document",
                                            $$v
                                          )
                                        },
                                        expression: "order.customer.document",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "6" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "order_maintain-email",
                                        type: "label",
                                        label: _vm.$t("Email"),
                                      },
                                      model: {
                                        value: _vm.order.customer.email,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.order.customer,
                                            "email",
                                            $$v
                                          )
                                        },
                                        expression: "order.customer.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "3" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "order_maintain-phone",
                                        type: "label",
                                        label: _vm.$t("Telefone"),
                                        filter: "phone",
                                      },
                                      model: {
                                        value: _vm.getCustomerTelephone.phone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.getCustomerTelephone,
                                            "phone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "getCustomerTelephone.phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "3" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "order_maintain-cellphone",
                                        type: "label",
                                        label: _vm.$t("Celular"),
                                        filter: "phone",
                                      },
                                      model: {
                                        value:
                                          _vm.getCustomerTelephone.cellphone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.getCustomerTelephone,
                                            "cellphone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "getCustomerTelephone.cellphone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.isDelivery
                                  ? _c(
                                      "b-col",
                                      { attrs: { md: "12" } },
                                      [
                                        _c("FormulateInput", {
                                          staticClass: "required",
                                          attrs: {
                                            id: "order_maintain-address_options",
                                            type: "vue-select",
                                            label: _vm.$t(
                                              "Endereço para entrega"
                                            ),
                                            options:
                                              _vm.getCustomerAddressOptions,
                                            clearable: false,
                                            validation: "required",
                                            placeholder: _vm.$t("Selecione"),
                                            disabled: _vm.isReadOnly,
                                          },
                                          on: { input: _vm.changeAddress },
                                          model: {
                                            value: _vm.order.addressSelected,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.order,
                                                "addressSelected",
                                                $$v
                                              )
                                            },
                                            expression: "order.addressSelected",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-1" },
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12", align: "right" } },
                            [
                              _vm.clientChangeEnabled
                                ? _c("e-button", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      id: "cancel_change_client",
                                      type: "button",
                                      variant: "outline-danger",
                                      icon: "x-circle",
                                      text: _vm.$t("Cancelar"),
                                    },
                                    on: { click: _vm.cancelChangeClient },
                                  })
                                : _vm._e(),
                              _vm.isReadOnly
                                ? _c("e-button", {
                                    attrs: {
                                      id: "change_client",
                                      type: "button",
                                      variant: "primary",
                                      icon: _vm.clientChangeEnabled
                                        ? "check-circle"
                                        : "pencil",
                                      text: _vm.clientChangeEnabled
                                        ? _vm.$t("Confirmar alteração")
                                        : _vm.$t("Alterar cliente"),
                                      disabled: !_vm.$can(
                                        "ChangeCustomer",
                                        "Sale"
                                      ),
                                      title: !_vm.$can("ChangeCustomer", "Sale")
                                        ? _vm.$t(
                                            "Você não possui permissão para alterar o cliente"
                                          )
                                        : "",
                                    },
                                    on: { click: _vm.changeClient },
                                  })
                                : _vm._e(),
                              _c("e-button", {
                                staticClass: "ml-1",
                                attrs: {
                                  id: "create_customer",
                                  type: "button",
                                  variant: "primary",
                                  icon: "plus-circle",
                                  text: _vm.$t("Novo Cliente"),
                                  disabled: !_vm.$can("Create", "Customer"),
                                  title: !_vm.$can("Create", "Customer")
                                    ? _vm.$t(
                                        "Você não possui permissão para criar cliente"
                                      )
                                    : "",
                                },
                                on: { click: _vm.createCustomer },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showDeliveryAddressCard
                ? _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      !_vm.isReadOnly
                        ? _c("e-address", {
                            attrs: { "show-loading": _vm.loading },
                            model: {
                              value: _vm.order.deliveryAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.order, "deliveryAddress", $$v)
                              },
                              expression: "order.deliveryAddress",
                            },
                          })
                        : _c(
                            "b-card-actions",
                            {
                              attrs: {
                                "no-actions": "",
                                title: _vm.$t("Endereço"),
                              },
                            },
                            [
                              _c("e-address", {
                                attrs: {
                                  "show-loading": _vm.loading,
                                  "read-only": true,
                                },
                                model: {
                                  value: _vm.order.deliveryAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.order, "deliveryAddress", $$v)
                                  },
                                  expression: "order.deliveryAddress",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.isDelivery
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card-actions",
                        {
                          attrs: {
                            "show-loading": _vm.loading,
                            "action-collapse": "",
                            title: _vm.$t("Dados de entrega"),
                          },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "order_maintain-delivery_fee_type",
                                      type: "radio",
                                      options: _vm.deliveryFeeLabel,
                                      label: _vm.$t("Tipo da taxa de entrega"),
                                      "element-class": ["d-flex", "mt-1"],
                                      disabled: !_vm.canEditDelivery,
                                      title: _vm.titleNotEditDelivery,
                                    },
                                    on: { input: _vm.onChangeDeliveryFeeType },
                                    model: {
                                      value: _vm.deliveryData.deliveryFeeType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.deliveryData,
                                          "deliveryFeeType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliveryData.deliveryFeeType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.order.origin === _vm.saleOriginEnum.APP ||
                              _vm.order.origin === _vm.saleOriginEnum.WEBSITE
                                ? _c(
                                    "b-col",
                                    { attrs: { md: "2" } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "order_maintain-is_tax_zero",
                                          value: _vm.order.isTaxZero,
                                          type: "label",
                                          label: _vm.$t("Taxa zero?"),
                                          options: _vm.yesNoTypes(),
                                          "is-badge": "",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isDeliveryPerKilometer
                                ? [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "3" } },
                                      [
                                        _c("FormulateInput", {
                                          attrs: {
                                            id: "order-fee_per_kilometer_value",
                                            type: "label",
                                            label: _vm.$t(
                                              "Valor da taxa por KM da loja"
                                            ),
                                            filter: "currency",
                                            instruction: _vm.$t(
                                              "Valor da taxa por quilômetro configurada para a loja selecionada."
                                            ),
                                          },
                                          model: {
                                            value:
                                              _vm.deliveryData
                                                .shippingFeePerKilometer,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.deliveryData,
                                                "shippingFeePerKilometer",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "deliveryData.shippingFeePerKilometer",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "3" } },
                                      [
                                        _c("FormulateInput", {
                                          staticClass: "required",
                                          attrs: {
                                            id: "order-distance_value",
                                            type: "text-number",
                                            precision: 2,
                                            label: _vm.$t("Distância em KM"),
                                            validation:
                                              "required|min:" +
                                              _vm.minKilometerDelivery,
                                            instructions: [
                                              {
                                                text: _vm.$t(
                                                  "Distância em quilômetros da loja até o endereço de entrega."
                                                ),
                                              },
                                              {
                                                text: _vm.$t(
                                                  "SALE.UI.MIN_DELIVERY_KM",
                                                  {
                                                    minKilometer:
                                                      _vm.minKilometerDelivery,
                                                  }
                                                ),
                                              },
                                            ],
                                            disabled: !_vm.canEditDelivery,
                                            title: _vm.titleNotEditDelivery,
                                          },
                                          on: { blur: _vm.onUpdateDelivery },
                                          model: {
                                            value:
                                              _vm.deliveryData.distanceValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.deliveryData,
                                                "distanceValue",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "deliveryData.distanceValue",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "3" } },
                                      [
                                        _c("FormulateInput", {
                                          attrs: {
                                            id: "order-single_fee_value",
                                            type: "label",
                                            label: _vm.$t(
                                              "Valor da taxa da loja"
                                            ),
                                            filter: "currency",
                                            instruction: _vm.$t(
                                              "Valor da taxa configurada para a loja selecionada."
                                            ),
                                          },
                                          model: {
                                            value: _vm.deliveryData.shippingFee,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.deliveryData,
                                                "shippingFee",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "deliveryData.shippingFee",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                              _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("e-slot-authenticated", {
                                    attrs: {
                                      "delegable-permission":
                                        _vm.deliveryDiscountPermission,
                                      "store-id": _vm.order.store
                                        ? _vm.order.store.id
                                        : 0,
                                      "is-valid-store": !!_vm.order.store,
                                      "is-read-only": _vm.isReadOnly,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "content",
                                          fn: function (slotProps) {
                                            return [
                                              slotProps.isReadOnly
                                                ? _c("FormulateInput", {
                                                    attrs: {
                                                      id: "order-delivery_discount",
                                                      name: "discount_percentage",
                                                      type: "label",
                                                      label: _vm.$t(
                                                        "Desconto da taxa da loja"
                                                      ),
                                                      filter: "currency",
                                                      instruction: _vm.$t(
                                                        "Valor de desconto aplicado apenas sobre o valor da entrega"
                                                      ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.deliveryData
                                                          .discount,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.deliveryData,
                                                          "discount",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "deliveryData.discount",
                                                    },
                                                  })
                                                : _c("FormulateInput", {
                                                    attrs: {
                                                      id: "order-delivery_discount",
                                                      name: "discount_percentage",
                                                      type: "text-number",
                                                      label: _vm.$t(
                                                        "Desconto da taxa de entrega"
                                                      ),
                                                      currency: _vm.$t("R$"),
                                                      validation: [
                                                        ["optional"],
                                                        [
                                                          "max",
                                                          _vm.deliveryData
                                                            .deliveryTax,
                                                        ],
                                                      ],
                                                      precision: 2,
                                                      instruction: _vm.$t(
                                                        "Valor de desconto aplicado apenas sobre o valor da entrega"
                                                      ),
                                                      disabled:
                                                        !_vm.canEditDelivery,
                                                      title:
                                                        _vm.titleNotEditDelivery,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.deliveryData
                                                          .discount,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.deliveryData,
                                                          "discount",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "deliveryData.discount",
                                                    },
                                                  }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      256827902
                                    ),
                                    model: {
                                      value: _vm.deliveryData.discountAuth,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.deliveryData,
                                          "discountAuth",
                                          $$v
                                        )
                                      },
                                      expression: "deliveryData.discountAuth",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.order.customer && _vm.order.storeId
            ? _c(
                "b-card-actions",
                {
                  attrs: {
                    "show-loading": _vm.loading || _vm.loadingProducts,
                    "action-collapse": "",
                    title: _vm.$t("Produtos"),
                  },
                },
                [
                  _c(
                    "b-row",
                    [
                      !_vm.isReadOnly
                        ? _c(
                            "b-col",
                            {
                              staticClass: "mb-1 d-flex justify-content-end",
                              attrs: { md: "12" },
                            },
                            [
                              _c("e-button", {
                                attrs: {
                                  "data-testid": "btnAddProduct",
                                  text: _vm.$t("Adicionar produto"),
                                  variant: "primary",
                                },
                                on: {
                                  click: function () {
                                    return _vm.onShowProductSidebar()
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c("sale-product-table", {
                            attrs: {
                              items: _vm.getProducts,
                              "is-read-only": _vm.isReadOnly,
                              "show-remove-when-exist-item":
                                !_vm.isConsignedWithPayment,
                              "show-update-when-exist-item":
                                !_vm.isConsignedWithPayment,
                              "show-update": true,
                              "show-remove": true,
                            },
                            on: {
                              "show-sidebar-update": _vm.onShowProductSidebar,
                              "remove-item": _vm.onRemoveProduct,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.hasColdOptionProducts || _vm.order.coldOption
                        ? _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "order-cold-option",
                                  type: _vm.isReadOnly ? "label" : "radio",
                                  options: _vm.coldOptionOptions(),
                                  label: _vm.$t("Estado dos produtos"),
                                  "element-class": [
                                    "d-flex",
                                    "mt-1",
                                    "flex-wrap",
                                  ],
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.order.coldOption,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.order, "coldOption", $$v)
                                  },
                                  expression: "order.coldOption",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-flex justify-content-end" },
                        [
                          _c("div", [
                            _vm.isDelivery
                              ? _c(
                                  "p",
                                  { staticClass: "h4 text-right" },
                                  [
                                    _c("e-instructions", {
                                      attrs: {
                                        title: _vm.$t("Taxa de conveniência"),
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("Taxa de conveniência:")
                                        ) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "h3" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.getOrderTotalDeliveryTax
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.getOrderTotalDiscount > 0
                              ? _c("p", { staticClass: "h4 text-right" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Desconto Total:")) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "h3" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.getOrderTotalDiscount
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.order.comission > 0
                              ? _c(
                                  "p",
                                  { staticClass: "h4 text-right" },
                                  [
                                    _c("e-instructions", {
                                      attrs: {
                                        title: _vm.$t("Comissão do garçom"),
                                        instruction: _vm.$t(
                                          "Valor da porcentagem da gorjeta do garçom."
                                        ),
                                      },
                                    }),
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("Comissão:")) + " "
                                    ),
                                    _c("span", { staticClass: "h3" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.order.comission
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("p", { staticClass: "h3 text-right" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Total do pedido:")) + " "
                              ),
                              _c("span", { staticClass: "h2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(_vm.getOrderTotalValue)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.getReturnedProducts && _vm.getReturnedProducts.length
                    ? _c(
                        "b-row",
                        { staticClass: "my-2" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("p", { staticClass: "h5" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("Produtos devolvidos")) +
                                    ": "
                                ),
                              ]),
                              _c("b-table", {
                                staticClass: "bordered",
                                attrs: {
                                  "show-empty": "",
                                  striped: "",
                                  fields: _vm.itemsReturnedFields,
                                  items: _vm.getReturnedProducts,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "custom-foot",
                                      fn: function () {
                                        return [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-right",
                                                attrs: { colspan: "4" },
                                              },
                                              [_vm._v(" Total ")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "text-right" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm.getTotalReturnedProducts
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3525864032
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.showProductsToReclaim
            ? _c(
                "b-card-actions",
                {
                  attrs: {
                    "show-loading": _vm.loading || _vm.loadingProducts,
                    "action-collapse": "",
                    title: _vm.$t("Retorno de produtos"),
                  },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-table", {
                            staticClass: "bordered",
                            attrs: {
                              "show-empty": "",
                              responsive: _vm.isReadOnly,
                              striped: "",
                              "empty-text": _vm.$t(
                                "Nenhum produtos para retorno registrado"
                              ),
                              fields: _vm.toReclaimFields,
                              items: _vm.itemsToReclaim,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(returnDateLimit)",
                                  fn: function (row) {
                                    return [
                                      _vm.isReadOnly
                                        ? _c("p", { staticClass: "m-0" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("date")(
                                                    row.item.returnDateLimit
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("FormulateInput", {
                                            attrs: {
                                              id:
                                                "reclaim_table-date_limit_" +
                                                (row.item.productAssociated
                                                  ? row.item.productAssociated
                                                      .id
                                                  : "") +
                                                "_" +
                                                (row.item.localItemId ||
                                                  row.item.itemId),
                                              type: "datepicker",
                                              placeholder: "dd/mm/yyyy",
                                              "aria-label":
                                                _vm.$t("Data limite"),
                                              min: _vm.today,
                                              validation: "required",
                                              "validation-messages": {
                                                required: _vm.$t(
                                                  "Data limite é obrigatório."
                                                ),
                                              },
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.handleDateToReturnChange(
                                                  row.index,
                                                  row.item.returnDateLimit
                                                )
                                              },
                                            },
                                            model: {
                                              value: row.item.returnDateLimit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row.item,
                                                  "returnDateLimit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "row.item.returnDateLimit",
                                            },
                                          }),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(name)",
                                  fn: function (row) {
                                    return [
                                      _c(
                                        "sup",
                                        { staticClass: "m-0 text-primary" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                row.item.productAssociated.name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("p", { staticClass: "m-0" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.item.skuProduct.name) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(quantity)",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            row.item.quantity -
                                              row.item.returnQuantityDamaged
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(contractualFine)",
                                  fn: function (row) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$options.filters.currency(
                                              row.item.skuProduct
                                                .contractualFine
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              27255852
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.order.id
            ? _c(
                "b-card-actions",
                {
                  attrs: {
                    "show-loading": _vm.loading || _vm.loadingProducts,
                    "action-collapse": "",
                    title: _vm.$t("Pagamentos"),
                  },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-table", {
                            staticClass: "bordered",
                            attrs: {
                              striped: "",
                              "show-empty": "",
                              "empty-text": _vm.isPix
                                ? _vm.$t("Aguardando pagamento PIX")
                                : _vm.$t("Nenhum pagamento registrado."),
                              fields: _vm.paymentFields,
                              items: _vm.order.payments
                                ? _vm.order.payments.filter(function (payment) {
                                    return (
                                      !payment.canceled ||
                                      payment.paymentMethodInterface ===
                                        "Online"
                                    )
                                  })
                                : [],
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(paymentMethod)",
                                  fn: function (row) {
                                    return [
                                      row.item.canceled
                                        ? _c(
                                            "b-badge",
                                            { attrs: { variant: "danger" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Cancelado")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              row.item.paymentMethod.name
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      row.item.cardBrand || row.item.nsu
                                        ? [
                                            _vm._v(" ( "),
                                            row.item.paymentMethodInterface
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .paymentMethodInterfaceLabel[
                                                          row.item
                                                            .paymentMethodInterface
                                                        ] || "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : _vm._e(),
                                            row.item.cardBrand
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.item.cardBrand ||
                                                          "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : _vm._e(),
                                            row.item.nsu
                                              ? [
                                                  _c("b", [_vm._v("NSU: ")]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.item.nsu || "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm._v(" ) "),
                                            _vm.order
                                              .onlinePaymentCancelation &&
                                            _vm.order.onlinePaymentCancelation
                                              .cancelRequestId
                                              ? _c("span", [
                                                  _vm._v(" - "),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Id do cancelamento: "
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.order
                                                          .onlinePaymentCancelation
                                                          .cancelRequestId
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              859444537
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.order.origin === "App" || _vm.order.origin === "Website"
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _vm.order.id &&
                      (_vm.order.status === "Completed" ||
                        _vm.order.status === "Canceled")
                        ? _c(
                            "b-card-actions",
                            {
                              attrs: {
                                "show-loading":
                                  _vm.loading || _vm.loadingProducts,
                                "action-collapse": "",
                                title: _vm.$t("Avaliação do cliente (NPS)"),
                              },
                            },
                            [
                              _vm.order.npsStatus !== "Answered"
                                ? _c("h5", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "O cliente ainda não respondeu à pesquisa NPS"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c(
                                    "div",
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "nps_average",
                                          type: "star-rating",
                                          label: _vm.$t("Nota final"),
                                          "show-number": true,
                                        },
                                        model: {
                                          value: _vm.order.npsAverage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.order,
                                              "npsAverage",
                                              $$v
                                            )
                                          },
                                          expression: "order.npsAverage",
                                        },
                                      }),
                                      _vm._l(
                                        _vm.order.npsAnswers.filter(function (
                                          answer
                                        ) {
                                          return answer.answer
                                        }),
                                        function (npsAnswer, index) {
                                          return _c("FormulateInput", {
                                            key: "nps_question_" + index,
                                            attrs: {
                                              type:
                                                npsAnswer.questionType ===
                                                "Rating"
                                                  ? "star-rating"
                                                  : "label",
                                              label: npsAnswer.question,
                                            },
                                            model: {
                                              value: npsAnswer.answer,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  npsAnswer,
                                                  "answer",
                                                  $$v
                                                )
                                              },
                                              expression: "npsAnswer.answer",
                                            },
                                          })
                                        }
                                      ),
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "nps-solved",
                                          name: "issueSolved",
                                          type: "switch",
                                          label: _vm.$t("Foi resolvido?"),
                                        },
                                        on: { input: _vm.saveNpsSolved },
                                        model: {
                                          value: _vm.order.npsSolved,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.order,
                                              "npsSolved",
                                              $$v
                                            )
                                          },
                                          expression: "order.npsSolved",
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.order.npsStatus === "Answered"
                    ? _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _vm.order.id &&
                          (_vm.order.status === "Completed" ||
                            _vm.order.status === "Canceled")
                            ? _c(
                                "b-card-actions",
                                {
                                  attrs: {
                                    "show-loading":
                                      _vm.loading || _vm.loadingProducts,
                                    "action-collapse": "",
                                    title: _vm.$t("Feedback do NPS"),
                                  },
                                },
                                [
                                  !_vm.order.npsFeedbacks.length
                                    ? _c("h5", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "Ainda não houve feedback da loja"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c(
                                        "div",
                                        _vm._l(
                                          _vm.order.npsFeedbacks,
                                          function (event, index) {
                                            return _c(
                                              "p",
                                              { key: "nps_event_" + index },
                                              [
                                                _c("em", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("datetime")(
                                                        event.createdDate
                                                      )
                                                    ) +
                                                      " (" +
                                                      _vm._s(event.createdBy) +
                                                      "):"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(event.description)
                                                  ),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-1" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12", align: "right" } },
                                        [
                                          _vm.isReadOnly
                                            ? _c("e-button", {
                                                attrs: {
                                                  id: "add_nps_feedback",
                                                  type: "button",
                                                  variant: "primary",
                                                  icon: "plus",
                                                  text: _vm.$t(
                                                    "Adicionar feedback"
                                                  ),
                                                  title: !_vm.$can(
                                                    "AddNpsFeedback",
                                                    "Sale"
                                                  )
                                                    ? _vm.$t(
                                                        "Você não possui permissão para adicionar feedback"
                                                      )
                                                    : "",
                                                },
                                                on: {
                                                  click: _vm.addNpsFeedback,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("fab", {
        attrs: {
          "main-tooltip": _vm.$t("Ações"),
          "main-icon": "keyboard_command_key",
          actions: _vm.actions,
          "fixed-tooltip": true,
          busy: _vm.loading,
        },
        on: {
          cancel: _vm.onCancel,
          "save-completed": _vm.saveOrder,
          "print-order": _vm.onPrint,
          "print-lending": _vm.onPrintLendingContract,
          "print-consigned-returned": _vm.onPrintConsignedReturned,
          "print-rent": _vm.onPrintRentContract,
          "print-sale-rent": _vm.onPrintSaleRent,
          "cancel-order": _vm.onCancelOrder,
        },
      }),
      _c("div", { staticClass: "pb-1" }),
      _c("e-modal-order-cancelation", {
        ref: "modalCancelation",
        on: {
          "after-confirm": function ($event) {
            return _vm.$router.push({ name: "order-list" })
          },
        },
      }),
      _c("modal-add-nps-feedback", {
        ref: "modalAddNpsFeedback",
        on: { "after-confirm": _vm.addNpsFeedbackData },
      }),
      _vm.order.storeId
        ? _c("sale-product-sidebar", {
            ref: "saleProductSidebar",
            attrs: {
              "store-id": _vm.order.storeId,
              "price-table-id": _vm.order.priceTableId,
              "items-added": _vm.order.products,
            },
            on: { update: _vm.onUpdateProduct, add: _vm.onAddProduct },
          })
        : _vm._e(),
      _c("bar-tab-consumption-info-sidebar", {
        ref: "barTabConsumptionSidebar",
        attrs: { "is-history-view": "", "read-only": "" },
        on: { "show-sale": _vm.onHideConsumptionSidebar },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }